import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import HistoryIcon from "@material-ui/icons/History";
import PersonIcon from "@material-ui/icons/Person";
import HomeWork from "@material-ui/icons/HomeWork";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import HouseIcon from "@material-ui/icons/Home";
import { Roles } from "src/router/routes";
import { useSelector } from "react-redux";
import { selectUserData } from "src/store/slicers/users";
import { useTranslation } from "react-i18next";

export const useSidebarItems = () => {
  const userData = useSelector(selectUserData);
  const { t } = useTranslation();

  return userData
    ? [
        ...(userData?.role === Roles.SuperAdmin
          ? [
              {
                href: "/admin/organizations",
                icon: HomeWork,
                title: t("organizations"),
                permissionKey: "acceptItems",
              },
            ]
          : []),
        ...(userData?.role === Roles.ClAdmin
          ? [
              {
                href: "/admin/addresses",
                icon: HouseIcon,
                title: t("addresses"),
                permissionKey: "acceptItems",
              },
            ]
          : []),
        ...(userData?.role !== Roles.Operator
          ? [
              {
                href: "/admin/users",
                icon: PersonIcon,
                title: t("users_page_title"),
                permissionKey: "acceptItems",
              },
            ]
          : []),
        {
          href: "/admin/orders",
          icon: LocalShippingIcon,
          title: t("parcel"),
          permissionKey: "acceptItems",
        },
        ...(userData?.role !== Roles.Operator
          ? [
              {
                href: "/admin/history",
                icon: HistoryIcon,
                title: t("history"),
                permissionKey: "acceptItems",
              },
            ]
          : []),
        ...(userData?.role === Roles.SuperAdmin
          ? [
              {
                href: "/admin/print-excel-data",
                icon: ImportExportIcon,
                title: t("excel_import"),
                permissionKey: "acceptItems",
              },
            ]
          : []),
      ]
    : [];
};
