import { useCallback, useEffect, useReducer } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { translatedProvinceStates } from "src/views/Orders/data/constants";
import {
  getCityVillages,
  getStreets,
  getBuilding,
} from "store/slicers/address";

const addressReducer = (state, { type, payload }) => {
  switch (type) {
    case "stateList":
      return { ...state, stateList: payload };
    case "cityVillageList":
      return { ...state, cityVillageList: payload };
    case "streetList":
      return { ...state, streetList: payload };
    case "buildingList":
      return { ...state, buildingList: payload };
    default:
  }
};

const addressInitialState = {
  stateList: [],
  cityVillageList: [],
  streetList: [],
};

const useGetStateCity = (watch, control, onReset, prefix = "") => {
  const fieldKeys = {
    ProvinceState: prefix ? `${prefix}.provinceState` : "provinceState",
    CityVillage: prefix ? `${prefix}.cityVillage` : "cityVillage",
    Street: prefix ? `${prefix}.street` : "street",
    Building: prefix ? `${prefix}.building` : "building",
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const provinceState = useWatch({
    control,
    name: fieldKeys.ProvinceState,
  });

  const cityVillage = watch(fieldKeys.CityVillage);
  const street = useWatch({ name: fieldKeys.Street, control });
  const building = watch(fieldKeys.Building);

  const [addressState, dispatchAddress] = useReducer(
    addressReducer,
    addressInitialState
  );

  const { cityVillageList, streetList } = addressState;

  const fetchAddressByType = async (fetchAction, type, actualId, params) => {
    const { meta, payload } = await dispatch(
      fetchAction(params ? { ...params, id: actualId } : actualId)
    );

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    dispatchAddress({ type, payload });
  };

  const fetchData = useCallback(
    async (fieldToWatch, listToFind, type, fetchAction, params) => {
      if (fieldToWatch) {
        const actualId =
          typeof fieldToWatch === "string"
            ? listToFind.find((i) => i.name === fieldToWatch)?.id
            : listToFind.find((i) => i.id === fieldToWatch?.id)?.id;

        if (!actualId) {
          return;
        }

        fetchAddressByType(fetchAction, type, actualId, params);
      }
    },
    []
  );

  const fetchStates = useCallback(() => {
    dispatchAddress({ type: "stateList", payload: translatedProvinceStates });
  }, [fetchData]);

  const fetchCityVillages = useCallback(() => {
    fetchData(
      provinceState,
      translatedProvinceStates,
      "cityVillageList",
      getCityVillages
    );
  }, [fetchData, provinceState]);

  const fetchStreets = useCallback(() => {
    if (!street || typeof street !== "string" || !street.trim()) {
      return;
    }

    fetchData(cityVillage, cityVillageList, "streetList", getStreets, {
      name: street,
    });
  }, [fetchData, cityVillage, street]);

  const fetchBuildings = useCallback(() => {
    if (!building || typeof building !== "string" || !building.trim()) {
      return;
    }

    fetchData(street, streetList, "buildingList", getBuilding, {
      name: building,
    });
  }, [fetchData, street, building]);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  useEffect(() => {
    fetchCityVillages();
  }, [provinceState]);

  useEffect(() => {
    fetchStreets();
  }, [cityVillage, street]);

  useEffect(() => {
    fetchBuildings();
  }, [building, street]);

  useEffect(() => {
    if (street && typeof street === "object") {
      if (!street?.id) {
        return;
      }
    }
  }, [street]);

  return { addressState, dispatchAddress };
};

export default useGetStateCity;
