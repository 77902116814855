import { useState, useEffect, useMemo, Fragment } from "react";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Divider,
  Tabs,
  Tab,
  AppBar,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import { makeStyles } from "@material-ui/styles";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrder,
  getOrderDetailById,
  getOrderPrice,
  selectEnclosuresData,
  selectIsEnclosuresEdited,
  selectOrderDetailData,
  setEnclosuresData,
  setEnclosuresEdited,
  updateOrder,
} from "store/slicers/order";
import InputField from "shared/ui/Input";
import SelectField from "shared/ui/Select";
import ButtonLoader from "components/ButtonLoader";
import {
  amountFormatter,
  onlyNumericalHandler,
  onlyNumericalPasteHandler,
  translateSelectOptions,
} from "src/helpers";
import {
  EPackageType,
  AddressStatusList,
  translatedServiceTypes,
  ECountriesList,
  EPackageTypesList,
  categoryTypesOptions,
  translatedAdditionalServices,
  ETypeList,
  EAdditionalServices,
  RestrictedUsers,
} from "../data/constants";
import AddressPicker from "components/AddressPicker";
import { useNavigate } from "react-router";
import { TabPanel, TabContext } from "@material-ui/lab";
import AutocompleteField from "src/shared/ui/Autocomplete";
import {
  fetchAddresses,
  selectAddressesList,
  rememberAddress,
} from "src/store/slicers/address";
import { AppDispatch } from "src/store";
import { useParams } from "react-router-dom";
import CircularLoader from "src/components/CircularLoader";
import useGetStateCity from "src/helpers/useGetStateCity";
import countriesList from "src/assets/constants/countries.json";
import {
  fetchServiceTypes,
  fetchServiceCategoryDirections,
  fetchCategoryTypes,
  removeServiceCategoryDirectionData,
  selectServiceCategoryDirectionData,
} from "src/store/slicers/resources";
import {
  IAddressDefaultValues,
  IManualOrderCreateUpdateFormValues,
  IReturnSenderAddress,
} from "../data/orders.interface";
import EnclosuresDialog from "./EnclosuresDialog";
import { useTranslation } from "react-i18next";
import CheckBoxField from "src/shared/ui/CheckBox";
import { selectUserData } from "src/store/slicers/users";
import { id } from "date-fns/locale";

const requiredRule = (t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
  };
};

const weightRules = (maxWeight: number, t: any) => {
  return {
    max: {
      value: maxWeight,
      message: `${t("maximum")} ${maxWeight} ${t("weight")}`,
    },
  };
};

const orderBy = (a, b, prop) => {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
};

const addressDefaultValues: IAddressDefaultValues = {
  name: "",
  surname: "",
  isLegal: false,
  email: "",
  phone: "",
  provinceStateId: "",
  provinceState: null,
  cityVillage: "",
  street: "",
  building: "",
  apartment: "",
  postalCode: "",
  legalName: "",
};

const defaultValues: IManualOrderCreateUpdateFormValues = {
  serviceId: "",
  additionalService: "",
  additionalServiceId: "",
  categoryId: "",
  weight: "",
  note: "",
  recipient: addressDefaultValues,
  returnSender: addressDefaultValues,
  returnSenderId: null,
  country: "",
  cod: "",
  smsNotification: false,
};

const useStyles = makeStyles((theme) => {
  return {
    label: {
      fontSize: 18,
      marginTop: 20,
      marginBottom: 20,
    },
    tabRoot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    enclosureTypeButtonArea: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    filledEnclosureBtn: {
      background: theme.palette.success.main,
      color: "white",
      "&:hover": {
        background: theme.palette.success.main,
      },
    },
    emptyEnclosureBtnSuccess: {
      background: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  };
});

interface IManualOrderCreateEditProps {
  mode: "create" | "edit";
}

const ManualOrderCreateEdit = ({ mode }: IManualOrderCreateEditProps) => {
  const [tabValue, setTabValue] = useState("0");
  const [datafetched, setDatafetched] = useState(false);
  const [enclosuresDialogOpen, setEnclosuresDialogOpen] = useState(false);
  const [smsNotificationObj, setSmsNotification] = useState(null);
  const addressList = useSelector(selectAddressesList);
  const serviceCategoryDirectionInfo = useSelector(
    selectServiceCategoryDirectionData
  );
  const orderDetailsData = useSelector(selectOrderDetailData);
  const enclosuresData = useSelector(selectEnclosuresData);
  const isEnclosuresEdited = useSelector(selectIsEnclosuresEdited);
  const { t } = useTranslation();
  const methods = useForm<IManualOrderCreateUpdateFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    watch,
    reset,
    getValues,
  } = methods;

  const {
    addressState: { stateList },
  } = useGetStateCity(watch, control, () => true);
  const navigate = useNavigate();
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [servicesTypesList, setServicesTypesList] = useState([]);
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [serviceDirectionData, setServiceDirectionData] = useState(null);
  const userInfo = useSelector(selectUserData);

  const packageTypeWatch = watch("serviceId");
  const weightWatch = watch("weight");

  const additionalServiceWatch = watch("additionalServiceId");
  const activeCategoryType = watch("categoryId");
  const watchCountry = watch("country");
  const actualAddressId = watch("returnSenderId")?.id;
  const watchSMSNotification = watch("smsNotification");

  const countriesData = useMemo(() => {
    if (RestrictedUsers.includes(userInfo.email)) {
      return [countriesList.find((i) => i.id === 11)];
    } else {
      return countriesList;
    }
  }, []);

  const submitCallback = async (formData) => {
    setLoading(true);

    const {
      returnSender,
      returnSenderId,
      additionalServiceId,
      recipient,
      country,
      smsNotification,
      ...rest
    } = formData;
    if (
      mode === "create" &&
      !enclosuresData?.length &&
      country.id !== ECountriesList.ARMENIA &&
      serviceDirectionData?.enclosureTypes?.length
    ) {
      toast.error(t("fill_enclosures"));
      setLoading(false);
      return;
    }

    const enclosuresFormData = serviceDirectionData?.enclosureTypes.map(
      (item) => {
        return {
          enclosureTypeId: item.id,
          enclosures: isEnclosuresEdited
            ? enclosuresData
            : JSON.parse(orderDetailsData?.enclosures)?.[0]?.Enclosures,
        };
      }
    );

    const additionalServiceIds = [
      ...(additionalServiceId ? [additionalServiceId] : []),
      ...(formData?.smsNotification ? [smsNotificationObj?.id] : []),
    ];

    const requestData = {
      ...rest,
      serviceCategoryDirectionId:
        serviceDirectionData?.serviceCategoryDirectionId ?? "",
      weight: formData.weight.toString(),
      enclosures:
        country?.id === ECountriesList.ARMENIA ? [] : enclosuresFormData,
      additionalServiceIds,
      recipient: {
        ...recipient,
        countryId: country.id ?? "",
        name: recipient.isLegal ? "" : recipient.name,
        surname: recipient.isLegal ? "" : recipient.surname,
        legalName: !recipient.isLegal ? "" : recipient.legalName,
        provinceStateId: recipient.provinceState?.id
          ? recipient.provinceState?.id
          : "",
        provinceStateName: !recipient.provinceState?.id
          ? recipient?.provinceState
          : "",
        cityVillage:
          typeof recipient.cityVillage === "object"
            ? recipient.cityVillage?.name
            : recipient.cityVillage,
        street:
          typeof recipient.street === "object"
            ? recipient.street?.name
            : recipient.street,
        building:
          typeof recipient.building === "object"
            ? recipient.building?.name
            : recipient.building,
      },
      ...(tabValue === "1"
        ? {
            returnSender: {
              ...returnSender,
              countryId: ECountriesList.ARMENIA,
              provinceStateId: returnSender.provinceState?.id
                ? returnSender.provinceState?.id
                : "",
              provinceStateName: !returnSender.provinceState?.id
                ? returnSender?.provinceState
                : "",
              cityVillage:
                typeof returnSender.cityVillage === "object"
                  ? returnSender.cityVillage?.name
                  : returnSender.cityVillage,
              street:
                typeof returnSender.street === "object"
                  ? returnSender.street?.name
                  : returnSender.street,
              building:
                typeof returnSender.building === "object"
                  ? returnSender.building?.name
                  : returnSender.building,
            },
          }
        : {
            returnSenderId: actualAddressId,
          }),
    };
    const action = mode === "edit" ? updateOrder : createOrder;
    const payloadData =
      mode === "edit"
        ? { formData: requestData, orderId: params.orderId }
        : requestData;

    const { meta } = await dispatch(action(payloadData));
    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }
    const successMessage =
      mode === "edit"
        ? t("parcel_changed_successfully")
        : t("parcel_created_successfully");
    toast.success(successMessage);
    dispatch(setEnclosuresEdited(false));
    navigate("/admin/orders", { replace: true });
    setLoading(false);
  };

  const fetchServiceTypesList = async (countryId: number) => {
    const { meta: serviceTypesListMeta, payload: serviceTypesListPayload } =
      await dispatch(fetchServiceTypes(countryId));

    if (serviceTypesListMeta.requestStatus !== "fulfilled") {
      return;
    }
    setServicesTypesList(serviceTypesListPayload);
  };

  const fetchCategoryTypesList = async (serviceTypeData) => {
    const { meta: categoryTypesListMeta, payload: categoryTypesListPayload } =
      await dispatch(fetchCategoryTypes(serviceTypeData));

    if (categoryTypesListMeta.requestStatus !== "fulfilled") {
      return;
    }
    setCategoryTypesList(categoryTypesListPayload);
    setSmsNotification(null);
  };

  const fetchAdditionalServicesTypesList = async (
    serviceCategoryDirectionId: number
  ) => {
    const { meta: additionalServiceMeta, payload: additionalServicePayload } =
      await dispatch(
        fetchServiceCategoryDirections(serviceCategoryDirectionId)
      );
    if (additionalServiceMeta.requestStatus !== "fulfilled") {
      return;
    }

    const additionalServicesList = [
      {
        id: 0,
        name: "not_mentioned",
      },
      ...additionalServicePayload.additionalServices,
    ];

    setServiceDirectionData({
      hasCOD: additionalServicePayload.hasCOD,
      serviceCategoryDirectionId: additionalServicePayload.id,
      additionalServices: additionalServicesList,
      service: additionalServicePayload?.service,
      enclosureTypes: additionalServicePayload.enclosureTypes,
    });

    const smsNotif = additionalServicePayload?.additionalServices?.find(
      (item) => item.id >= 16 && item.id <= 18
    );

    if (smsNotif) {
      setSmsNotification(smsNotif);
    } else {
      setSmsNotification(null);
    }
  };

  const fetchData = async () => {
    setSpinner(true);
    const addressesResponse = await dispatch(fetchAddresses());
    if (mode === "edit") {
      const { meta, payload }: any = await dispatch(
        getOrderDetailById(params.orderId)
      );

      if (meta.requestStatus !== "fulfilled") {
        setSpinner(false);
        return;
      }

      if (payload) {
        const {
          service,
          category,
          weight,
          note1,
          note2,
          cod,
          serviceCategoryDirectionId,
          additionalServiceIds,
          country,
          recipient: {
            provinceStateId: recipientProvince,
            provinceStateName: recipientProvinceName,
            isLegal,
            ...restRecipient
          },
          returnSender: {
            provinceStateId: returnSenderProvince,
            provinceStateName,
            ...restReturnSender
          },
          returnSenderId,
        } = payload;
        let smsNotifId = 0;
        if (additionalServiceIds?.length > 1) {
          if (additionalServiceIds[1] >= 16 && additionalServiceIds[1] <= 18) {
            smsNotifId = additionalServiceIds[1];
          }
        } else {
          if (additionalServiceIds[0] >= 16 && additionalServiceIds[0] <= 18) {
            smsNotifId = additionalServiceIds[0];
          }
        }

        const newForm = {
          serviceId: service.id,
          additionalServiceIds,
          categoryId: category.id,
          weight,
          country,
          additionalServiceId:
            additionalServiceIds?.length &&
            additionalServiceIds[0] &&
            additionalServiceIds[0] < 16
              ? additionalServiceIds[0]
              : 0,
          smsNotification: smsNotifId ? !!smsNotifId : false,
          note1: note1 || "",
          note2: note2 || "",
          cod: cod || "",
          recipient: {
            ...restRecipient,
            isLegal,
            provinceState:
              stateList.find((i) => i.id === recipientProvince) ??
              recipientProvinceName,
          },
          returnSender: {
            ...restReturnSender,
            provinceState:
              stateList.find((i) => i.id === returnSenderProvince) ??
              provinceStateName,
          },
          returnSenderId: addressesResponse.payload?.find(
            (i) => i.id === returnSenderId
          ),
        };
        if (country?.id !== ECountriesList.ARMENIA) {
          setTabValue("1");
        }
        /////////// fetch service types ///////////
        await fetchServiceTypesList(country.id);
        /////////// fetch category types ///////////
        const serviceTypeData = {
          serviceId: service.id,
          countryId: country.id,
        };
        await fetchCategoryTypesList(serviceTypeData);
        /////////// fetch additional services types ///////////
        await fetchAdditionalServicesTypesList(serviceCategoryDirectionId);
        reset(newForm);
        setDatafetched(true);
        setTimeout(() => {
          setSpinner(false);
        }, 2000);
      }
    }
    if (mode === "create") {
      await fetchServiceTypesList(countriesList[0].id);
    }
    setDatafetched(true);
    setSpinner(false);
  };

  const handleChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  const formattedAddressList = useMemo(() => {
    if (!addressList?.length) {
      return;
    }
    const sortedAddressesList = [...addressList];
    return sortedAddressesList
      ?.sort(
        (a: IReturnSenderAddress, b: IReturnSenderAddress) =>
          a.userDefaultReturnSender - b.userDefaultReturnSender
      )
      ?.map((item) => {
        return {
          groupLabel: t(
            AddressStatusList[item.userDefaultReturnSender - 1]?.label
          ),
          ...item,
        };
      });
  }, [addressList, t]);

  const handleRememberAddress = async () => {
    if (!actualAddressId) {
      return;
    }

    const { meta } = await dispatch(rememberAddress(actualAddressId));

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    toast.success(t("address_is_remembered"));
  };

  const handleGetOrderPrice = async () => {
    setLoading(true);
    const { serviceCategoryDirectionId } = serviceDirectionData;

    const additionalServiceIds = [
      ...(getValues("additionalServiceId")
        ? [getValues("additionalServiceId")]
        : []),
      ...(getValues("smsNotification") ? [smsNotificationObj?.id] : []),
    ];

    const requestParams = new URLSearchParams({
      ...(serviceCategoryDirectionId ? { serviceCategoryDirectionId } : {}),
      weight: getValues("weight") || 0,
    });
    if (additionalServiceIds?.length) {
      additionalServiceIds.forEach((item) => {
        requestParams.append("additionalServiceIds", item);
      });
    }
    const formDataQuery = requestParams.toString();
    const { meta, payload } = await dispatch(getOrderPrice(formDataQuery));

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    setEstimatedPrice(payload);
    setLoading(false);
  };

  const handleSelectCountry = async (newValue) => {
    setServicesTypesList([]);
    setCategoryTypesList([]);
    setServiceDirectionData({});
    dispatch(removeServiceCategoryDirectionData());

    reset({
      ...watch(),
      serviceId: "",
      categoryId: "",
      additionalServiceId: "",
      cod: "",
      weight: "",
      recipient: addressDefaultValues,
      returnSender: addressDefaultValues,
      smsNotification: false,
    });
    if (newValue.id !== ECountriesList.ARMENIA) {
      setTabValue("1");
    } else {
      setTabValue("0");
    }
    fetchServiceTypesList(newValue.id);
  };

  const handleSelectServiceType = async (newValue: string) => {
    setCategoryTypesList([]);
    setServiceDirectionData({});
    dispatch(removeServiceCategoryDirectionData());
    reset({
      ...watch(),
      categoryId: "",
      additionalServiceId: "",
      cod: "",
      weight: "",
      smsNotification: false,
    });
    const serviceTypeData = {
      serviceId: newValue,
      countryId: watchCountry?.id,
    };
    fetchCategoryTypesList(serviceTypeData);
  };

  const handleSelectCategoryType = async (newValue) => {
    reset({
      ...watch(),
      additionalServiceId: "",
      weight: "",
      cod: "",
      smsNotification: false,
    });
    const selectedService = categoryTypesList?.find(
      (item) => item.id == newValue
    );
    fetchAdditionalServicesTypesList(
      selectedService?.serviceCategoryDirectionId
    );
  };

  const availibleServicesList = useMemo(() => {
    if (servicesTypesList?.length) {
      if (watchCountry?.id) {
        if (RestrictedUsers.includes(userInfo.email)) {
          return [servicesTypesList.find((i) => i.id === EPackageType.PARCEL)];
        }
        return servicesTypesList.filter(
          (item) =>
            item.id !== EPackageTypesList.REVERT &&
            item.id !== EPackageTypesList.SEKOGRAM
        );
      }
    }
  }, [servicesTypesList, watchCountry?.id]);

  const filteredCategoryTypeList = useMemo(() => {
    if (categoryTypesList?.length) {
      return (
        categoryTypesList.filter((item) => item.id !== ETypeList.VALUED) || []
      );
    }
  }, [categoryTypesList]);

  const filteredAdditionalServicesList = useMemo(() => {
    if (serviceDirectionData?.additionalServices?.length) {
      return serviceDirectionData?.additionalServices?.filter(
        (item) =>
          !(
            item.id >= EAdditionalServices.DomesticSMS &&
            item.id <= EAdditionalServices.InternationalSMSFree
          )
      );
    }
  }, [serviceDirectionData?.additionalServices]);

  const enclosuresBtnText = useMemo(() => {
    if (serviceDirectionData?.enclosureTypes) {
      const filteredTypes = serviceDirectionData?.enclosureTypes.filter(
        (item) => item.id !== 3
      );
      const text = filteredTypes.map((item) => item.code);
      if (filteredTypes?.length > 1) {
        return text.join(" & ");
      }
      return text;
    }
  }, [serviceDirectionData?.enclosureTypes, orderDetailsData]);

  const enclosuresBtnColor = useMemo(() => {
    const hasEnclosure = orderDetailsData?.enclosures
      ? JSON.parse(orderDetailsData?.enclosures)?.[0]?.Enclosures
      : [];
    if (enclosuresData?.length && mode === "create") {
      return classes.filledEnclosureBtn;
    }
    if (hasEnclosure?.length && mode === "edit") {
      return classes.filledEnclosureBtn;
    }
    return classes.emptyEnclosureBtnSuccess;
  }, [enclosuresData, serviceDirectionData?.enclosureTypes, orderDetailsData]);

  useEffect(() => {
    setEstimatedPrice(0);
  }, [weightWatch, additionalServiceWatch, activeCategoryType]);

  useEffect(() => {
    if (stateList?.length && !datafetched) {
      fetchData();
    }
  }, [stateList]);

  useEffect(() => {
    if (!addressList?.length) {
      return;
    }

    const sortedAddressList = [...addressList].sort((a, b) =>
      orderBy(a, b, "userDefaultReturnSender")
    );

    const defaultAddress = sortedAddressList?.find(
      (i) => i.userDefaultReturnSender === 1 || i.userDefaultReturnSender === 2
    );

    if (defaultAddress) {
      reset({
        ...defaultValues,
        ...watch(),
        returnSenderId: defaultAddress,
      });
    }
  }, [addressList]);

  useEffect(() => {
    return () => {
      setServicesTypesList([]);
      setCategoryTypesList([]);
      setServiceDirectionData(null);
      dispatch(removeServiceCategoryDirectionData());
      dispatch(setEnclosuresData(null));
    };
  }, []);

  useEffect(() => {
    if (RestrictedUsers.includes(userInfo.email)) {
      reset({
        ...watch(),
        country: "",
      });
    } else {
      reset({
        ...watch(),
        country: countriesList[0],
      });
    }
  }, [userInfo]);

  return (
    <Fragment>
      {enclosuresDialogOpen && (
        <EnclosuresDialog
          open={enclosuresDialogOpen}
          dialogTitle={t("fill_enclosures")}
          setOpen={setEnclosuresDialogOpen}
          mode={mode}
        />
      )}

      <form onSubmit={handleSubmit(submitCallback)} className={classes.form}>
        <CircularLoader maxContent={false} loading={spinner}>
          <Box p={2} component={Paper}>
            <FormProvider {...methods}>
              <Typography variant="h3">
                {t("of_parcel")}{" "}
                {mode === "edit" ? t("modification") : t("creation")}{" "}
              </Typography>
              <Box mt={4} mb={2}>
                <Typography variant="h4" color="textSecondary">
                  {t("general_info")}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <AutocompleteField
                    size="medium"
                    name="country"
                    label={t("country")}
                    control={control}
                    rules={requiredRule(t)}
                    options={countriesData || []}
                    onChange={handleSelectCountry}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    name="serviceId"
                    label={t("parcel_type")}
                    control={control}
                    options={availibleServicesList || []}
                    rules={requiredRule(t)}
                    onChange={handleSelectServiceType}
                    disabled={!availibleServicesList?.length}
                    isTranslated
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    name="categoryId"
                    label={t("type")}
                    control={control}
                    options={filteredCategoryTypeList}
                    rules={requiredRule(t)}
                    onChange={handleSelectCategoryType}
                    disabled={!filteredCategoryTypeList?.length}
                    isTranslated
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    name="weight"
                    label={`${t("weight")}(${t("gram")})`}
                    control={control}
                    rules={weightRules(
                      serviceDirectionData?.service?.maxWeightLimit,
                      t
                    )}
                    disabled={packageTypeWatch == EPackageType.POSTCARD}
                    onKeyPress={onlyNumericalHandler}
                    onPaste={onlyNumericalPasteHandler}
                  />
                  <Typography>{t("30kgReminder")}</Typography>
                </Grid>
                {serviceCategoryDirectionInfo?.additionalServices?.length ? (
                  <Grid item xs={4}>
                    <SelectField
                      name="additionalServiceId"
                      label={t("additional_services")}
                      control={control}
                      keyProp="id"
                      valueProp="id"
                      options={filteredAdditionalServicesList}
                      rules={requiredRule(t)}
                      isTranslated
                    />
                  </Grid>
                ) : null}

                {serviceDirectionData?.hasCOD && (
                  <Grid item xs={4}>
                    <InputField
                      name="cod"
                      label={t("cod_field")}
                      control={control}
                      rules={{
                        maxLength: {
                          value: 11,
                          message: `${t("maximum")} 11 ${t("character")}`,
                        },
                      }}
                      onKeyPress={onlyNumericalHandler}
                      onPaste={onlyNumericalPasteHandler}
                    />
                  </Grid>
                )}
                {/* Add when be is ready */}
                {/* {Number(activeCategoryType) === 3 && (
              <Grid item xs={4}>
                <InputField
                  name="valuedAmount"
                  label="Գնահատված արժեք"
                  control={control}
                  rules={defaultRules(10)}
                  onKeyPress={onlyNumericalHandler}
                  onPaste={onlyNumericalPasteHandler}
                />
              </Grid>
            )} */}

                <Grid item xs={4}>
                  <InputField
                    name="note1"
                    label={`${t("additional")} ${t("note")} 1`}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    name="note2"
                    label={`${t("additional")} ${t("note")} 2`}
                    control={control}
                  />
                </Grid>
                {smsNotificationObj?.id ? (
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CheckBoxField
                          name="smsNotification"
                          control={control}
                        />
                      }
                      label={`${
                        smsNotificationObj.id === 16
                          ? t("domestic_sms")
                          : t("international_sms")
                      }-price:${smsNotificationObj.fee}AMD`}
                    />
                  </Grid>
                ) : null}

                {serviceDirectionData?.enclosureTypes?.length &&
                watchCountry?.id !== ECountriesList.ARMENIA ? (
                  <Grid item xs={4} className={classes.enclosureTypeButtonArea}>
                    <Button
                      variant="contained"
                      className={enclosuresBtnColor}
                      onClick={() => setEnclosuresDialogOpen(true)}
                    >
                      {enclosuresBtnText}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>

              <Box p={4}>
                <Divider />
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h4" color="textSecondary">
                  {t("recipient_info")}
                </Typography>
              </Box>
              <AddressPicker
                prefix={"recipient"}
                countryID={watchCountry?.id}
                isPhoneNumberRequired={watchSMSNotification}
              />

              <Box p={4}>
                <Divider />
              </Box>

              <Box mt={2} mb={2}>
                <Typography variant="h4" color="textSecondary">
                  {t("sender_return_sender_address")}
                </Typography>
              </Box>
              <TabContext value={tabValue.toString()}>
                <Box className={classes.tabRoot}>
                  <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleChange}>
                      {watchCountry?.id === ECountriesList.ARMENIA && (
                        <Tab label={t("choose")} value={"0"} />
                      )}
                      <Tab label={t("fill")} value={"1"} />
                    </Tabs>
                  </AppBar>
                  {tabValue === "0" &&
                    watchCountry?.id === ECountriesList.ARMENIA && (
                      <TabPanel value={tabValue.toString()} index={0}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <AutocompleteField
                              size="medium"
                              groupBy={(option) => option.groupLabel}
                              name="returnSenderId"
                              optionLabelKey="addressString"
                              label={t("address")}
                              control={control}
                              options={formattedAddressList || []}
                              disabled={!formattedAddressList?.length}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {dirtyFields?.returnSenderId && (
                              <Box mt={1}>
                                <ButtonLoader
                                  className={classes.btn}
                                  isLoading={loading}
                                  onClick={handleRememberAddress}
                                >
                                  {t("remember")}
                                </ButtonLoader>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>
                    )}

                  {tabValue === "1" && (
                    <TabPanel value={tabValue.toString()} index={1}>
                      <AddressPicker
                        prefix={"returnSender"}
                        countryID={watchCountry?.id}
                        isPhoneNumberRequired={false}
                      />
                    </TabPanel>
                  )}
                </Box>
              </TabContext>

              <Box display="flex" py={4} alignItems="center">
                {!watch("weight") ||
                watch("weight") === "0" ||
                estimatedPrice ? (
                  <Fragment>
                    <ButtonLoader
                      className={classes.btn}
                      type="submit"
                      isLoading={loading}
                    >
                      {t("approve")}
                    </ButtonLoader>
                    <Box ml={2}>
                      <Typography style={{ fontWeight: "bold" }}>
                        {!estimatedPrice
                          ? t(
                              "parcel_price_will_be_calculated_after_entering_post"
                            )
                          : `${t("your_parcel_amount")} ${" "}
                    ${amountFormatter(estimatedPrice.toString())} 
                    ${t("dram")}.`}
                      </Typography>
                    </Box>
                  </Fragment>
                ) : (
                  <ButtonLoader
                    color="secondary"
                    className={classes.btn}
                    onClick={handleGetOrderPrice}
                    isLoading={loading}
                    disabled={!activeCategoryType || !serviceDirectionData}
                  >
                    {t("calc_amount")}
                  </ButtonLoader>
                )}
              </Box>
            </FormProvider>
          </Box>
        </CircularLoader>
      </form>
    </Fragment>
  );
};

export default ManualOrderCreateEdit;
