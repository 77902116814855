import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
} from "@material-ui/core";

import NavItem from "./NavItem";
import { useSidebarItems } from "./routesData";
import { checkSidebarRoutePermissions } from "src/router/permissions";
import { useLocation } from "react-router";

const useStyles = makeStyles({
  mobileDrawer: {
    width: 270,
  },
  desktopDrawer: {
    zIndex: 1,
    width: 270,
    top: 74,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
});

const NavBar = ({ onMobileClose, openMobile }) => {
  const items = useSidebarItems();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    openMobile && onMobileClose?.();
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => {
            return (
              item.permissionKey &&
              item && (
                <NavItem
                  href={
                    typeof item.href === "function" ? item.href() : item.href
                  }
                  key={item.href}
                  title={item.title}
                  icon={item.icon}
                  role={item.role}
                />
              )
            );
          })}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
